// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/channel/fetchers/channel-switch-fetcher.tsx"
);
import.meta.hot.lastModified = "1743476228000";
}
// REMIX HMR END

import React from 'react'
import {
  ShouldRevalidateFunction,
  useFetcher,
  useRevalidator,
} from '@remix-run/react'
import {
  LoaderFunctionArgs,
  ActionFunctionArgs,
  json,
} from '@remix-run/server-runtime'

import {
  AdministratorDetailsFragment,
  Channel,
  ChannelList,
  ListChannelFragment,
} from '~admin/generated/graphql'
import { getStaffByUserId } from '~admin/providers/staff/provider'
import { getAlertServer } from '~common/components/alert/server/index.server'
import { processReferer } from '~admin/utils/revalidate'
import { getActiveAdministratorDetails } from '~admin/providers/administrator/provider'
import { PartialWithRequired } from '~common/types'
import {
  getTimezone,
  setTimezone,
} from '~common/components/alert/server/default-session.server'

import { config } from '../config'
import { createChannelToken } from '../utils'
import { ChannelToken } from '../types'

import { getActiveUser, setActiveUser } from './cookie.server'

type ChannelArray = PartialWithRequired<Channel, 'id' | 'code' | 'token'>[]

export async function loader({ request, context }: LoaderFunctionArgs) {
  let channelToken
  let availableChannels
  let error
  try {
    const activeUser = await getActiveUser(request)
    channelToken = activeUser.get('channelToken')
    try {
      // we want to ensure superadmin or list channel permission, but it seems this permission
      // can only be read through an expensive query, so we're just aiming to catch an error here
      const activeUserId = activeUser.get('activeUserId')
      if (activeUserId) {
        // we don't have staff for all users, such as superadmin
        // this logs a nasty error server side
        const staff = await getStaffByUserId(activeUserId, {
          request,
        })
        const staffUser = staff.rsv_staffByUserId
        if (!staffUser) {
          throw new Error('Missing staff user')
        }
        const roleChannels = Object.values(
          staffUser.user.roles.reduce(
            (acc, val) => {
              if (val.code === '__super_admin_role__') {
                throw new Error('Super admin user')
              }
              val.channels.forEach((c) => {
                acc[`${c.token}`] = c
              })
              return acc
            },
            {} as { [key: string]: ListChannelFragment },
          ),
        )
        availableChannels = {
          items: roleChannels,
          totalItems: roleChannels.length,
        }
      } else {
        throw new Error('Missing staff user')
      }
    } catch (err: any) {
      console.log('err1', err.message)
      try {
        const adminRes = await getActiveAdministratorDetails({ request })
        const channels = Object.values(
          adminRes.activeAdministrator?.user.roles.reduce(
            (acc, role) => {
              role.channels.forEach((c) => {
                acc[`${c.token}`] = c
              })
              return acc
            },
            {} as {
              [
                key: string
              ]: AdministratorDetailsFragment['user']['roles'][number]['channels'][number]
            },
          ) ?? {},
        )
        availableChannels = {
          items: channels,
          totalItems: channels.length,
        }
      } catch (err: any) {
        console.log('Error loading channels', err)
        availableChannels = {
          items: [],
          totalItems: 0,
        }
      }
    }
  } catch (err: any) {
    console.log('other error loading channels', err)
    error = err.message
  }

  return json({ channelToken, channels: availableChannels, error })
}

export async function action({ request }: ActionFunctionArgs) {
  let error
  let channelToken = null
  const alertServer = getAlertServer()
  const referer = request.headers.get('referer')
  const origin = request.headers.get('origin')
  const url = new URL(request.url)
  if (url.searchParams.get('refresh')) {
    return
  }
  const formData = await request.formData()
  const token = formData.get('token') as string
  const name = formData.get('name') as string
  const avatar = formData.get('avatar') as string
  const timezone = formData.get('timezone') as string
  const messagingClientToken = formData.get('messagingClientToken') as string
  console.log(`Switching channel to: ${name}, ${token}`)
  const headers = new Headers({ 'Cache-Control': 'no-store' })
  try {
    const activeUser = await getActiveUser(request)
    channelToken =
      name.length && token.length
        ? {
            name,
            token,
            avatar: avatar?.length ? avatar : undefined,
            messagingClientToken: messagingClientToken?.length
              ? messagingClientToken
              : null,
          }
        : null
    let cookie
    if (activeUser) {
      activeUser.set('channelToken', channelToken)
      cookie = await setActiveUser(activeUser)
    } else {
      cookie = await setActiveUser({
        activeUserId: null,
        channelToken,
      } as any)
    }
    headers.append('Set-Cookie', cookie)
    if (timezone) {
      const session = await getTimezone(request)
      if (session.get('timezone') !== timezone) {
        const ck = await setTimezone(request, timezone ?? '')
        headers.append('Set-Cookie', ck)
      }
    }
  } catch (err: any) {
    error = err.message
  }

  if (error) {
    return json({ channelToken, error }, { headers })
  }
  const bpath = processReferer(referer || '', origin || '')
  return alertServer.redirectWithSuccess(
    `${bpath}${bpath.includes('?') ? '&' : '?'}refresh=${Math.ceil(Math.random() * 100000)}`,
    {
      title: 'Success',
      message: `Changed channel to ${name}`,
    },
    { headers },
  )
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
  actionResult,
  currentParams,
  currentUrl,
  defaultShouldRevalidate,
  formAction,
  formData,
  formEncType,
  formMethod,
  nextParams,
  nextUrl,
}) => {
  return true
}

export const useChannelSwitcher = () => {
  const [exception, setException] = React.useState<string | undefined>()
  const loaderFetcher = useFetcher<typeof loader>({
    key: 'channel-switch-loader',
  })
  const fetcher = useFetcher<typeof action>({
    key: 'channel-switch-action',
  })

  const revalidator = useRevalidator()

  const [token, setToken] = React.useState<ChannelToken | null>()
  const [items, setItems] = React.useState<ChannelList | undefined>()

  const { data: loaderData, state: loaderState } = loaderFetcher
  const { data: fetcherData, state: fetcherState } = fetcher

  React.useEffect(() => {
    if (!fetcherData || fetcherData.error || fetcherState !== 'idle') {
      return
    }
    if (fetcherData) {
      setException(undefined)
      setToken(fetcherData.channelToken)
      if (revalidator.state === 'idle') {
        // if the token changes. We should trigger a page reload
        revalidator.revalidate()
      }
    }
  }, [fetcherData, fetcherState])

  React.useEffect(() => {
    if (!loaderData || loaderData.error || loaderState !== 'idle') {
      return
    }
    if (loaderData) {
      setException(undefined)
      setToken(loaderData.channelToken)
      if (loaderData.channels) {
        setItems(loaderData.channels as ChannelList)
      }
    }
  }, [loaderData, loaderState])

  React.useEffect(() => {
    if (fetcherData?.error) {
      setException(fetcherData.error)
    }
  }, [fetcherData])

  React.useEffect(() => {
    if (loaderData?.error) {
      setException(loaderData.error)
    }
  }, [loaderData])

  const handleLoadData = React.useCallback(() => {
    loaderFetcher.load(`${config.apiPath}/list`)
  }, [])

  const handleSwitch = React.useCallback(
    (channelToken: ChannelToken | null) => {
      let newChannel = channelToken
      if (!newChannel) {
        const firstChannel = items?.items[0]
        if (firstChannel) {
          newChannel = createChannelToken(firstChannel)
        }
      }
      fetcher.submit(
        {
          name: newChannel?.name ?? '',
          token: newChannel?.token ?? '',
          avatar: newChannel?.avatar ?? '',
          timezone: newChannel?.timezone ?? '',
          messagingClientToken: newChannel?.messagingClientToken ?? '',
        },
        {
          action: `${config.apiPath}/token`,
          method: 'POST',
        },
      )
    },
    [items],
  )

  const handleReload = React.useCallback(() => {
    handleLoadData()
  }, [handleLoadData])

  React.useEffect(() => {
    if (!items) {
      setTimeout(handleReload, 1500)
    }
  }, [])

  // This is now handled post login
  // React.useEffect(() => {
  //   if (items && loaderState === 'idle') {
  //     if (
  //       items.totalItems === 1 &&
  //       items.items[0].code !== '__default_channel__'
  //     ) {
  //       // we want to set the channel to the available one if it is on the default and you don't have access to it
  //       handleSwitch(null)
  //     }
  //   }
  // }, [items, loaderState])

  return {
    channels: items,
    channelToken: token,
    exception,
    loading: fetcherState !== 'idle' || loaderState !== 'idle',
    onChange: handleSwitch,
    onReload: handleReload,
  }
}
